import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import React from "react";

const Maps = () => {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyDDwyuPDsT7PP4BkYMVRXxBbwYcNg3naq0",
  });

  const position = {
    lat: -27.132795186760706,
    lng: -48.60231224295547,
  };

  return (
    <div>
      {isLoaded ? (
        <GoogleMap
          mapContainerStyle={{
            width: '100%',
            height: '500px'
          }}
          center={position}
          zoom={15}
        >
          <Marker 
            position={position} 
            options={{
              label: {
                text: "The Barber Den",
                className: "map-marker"
              }
            }}
          />
        </GoogleMap>
      ) : (
        <>{console.log("não carregou")}</>
      )}
    </div>
  );
};

export default Maps;

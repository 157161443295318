import React from "react";
import Maps from "./Maps/maps";

export const Mapa = (props) => {
  return (
    <div id="testimonials">
      <div className="container">
        <div className="section-title text-center">
          <h2>Nos encontre em Itapema - Santa cataria</h2>
        </div>
        <div>
          <Maps />
        </div>
      </div>
    </div>
  );
};

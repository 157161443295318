import React, { useEffect, useState } from "react";
import { styled } from "styled-components";

const BtnModalMobile = styled.button`
    display: none;
    border: none;
    margin: 2px;
    padding: 2px;
    border-radius: 20px;
    background-color: #f0a500;;
    @media (max-width: 768px) {
      display: block;
    }
  `;
  const BtnModalDesktop = styled.button`
    border: none;
    margin: 2px;
    padding: 2px;
    background-color: #7F8487;
    border-radius: 20px;
    &:hover {
      background: linear-gradient(
        to right,
        #F0A500 0%,
        #ffb921 100%
      );
      transition: all 0.5s;
    }
    @media (max-width: 769px) {
      display: none;
    }
  `;

  const LargeImg = styled.img`
    max-height: 80vh;
  `

  const SmallImg = styled.img`
    max-height: 500px;
    border-radius: 20px;
  `

export const Image = ({ title, largeImage, smallImage }) => {
  
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    window.addEventListener("click", (e) => {
      if (e.target === document.getElementById("modal")) {
        setIsModalOpen(false);
      }
    });
  }, []);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  return (
    <>
      <BtnModalMobile>
        <SmallImg src={smallImage} className="img-responsive" alt={title} />{" "}
      </BtnModalMobile>
      <BtnModalDesktop
        onClick={isModalOpen ? handleCloseModal : handleOpenModal}
      >
        <SmallImg src={smallImage} className="img-responsive" alt={title} />{" "}
        <div id="modal" className={isModalOpen ? "show" : "hidden"}>
          <div className="modal-content">
            <LargeImg src={largeImage} alt={title} className="modal-img" />
          </div>
        </div>
      </BtnModalDesktop>
    </>
  );
};

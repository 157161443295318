import React from "react";

export const Contact = (props) => {
  return (
    <div>
      <div id="contact">
        <div className="container">
          <div className="col-md-8">
            <div className="row">
              <div className="section-title">
                <h2>Entre em contato <i className="fa fa-whatsapp"></i></h2>
                <p>
                  Nos envie uma mensagem no WhatsApp e nós iremos lhe atender
                  com maior carinho e atenção.
                </p>
                <a href={props.data ? props.data.whatsapp : "/"} target="blank">
                  <button type="submit" className="btn btn-custom btn-lg">
                    Enviar mensagem
                  </button>
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-3 col-md-offset-1 contact-info">
            <div className="contact-item">
              <h3>Informações para contato</h3>
              <p>
                <span>
                  <i className="fa fa-map-marker"></i> Endereço
                </span>
                {props.data ? props.data.address : "loading"}
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-phone"></i> Telefone
                </span>{" "}
                {props.data ? props.data.phone : "loading"}
              </p>
            </div>
        
          </div>
          <div className="col-md-12">
            <div className="row">
              <div className="social">
                <ul>
                  <li>
                  </li>
                  <li>
                    <a
                      href={props.data ? props.data.instagram : "/"}
                      target="blank"
                    >
                      <i className="fa fa-instagram"></i>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.site : "/"} target="blank">
                      <i className="fa fa-globe"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="footer">
        <div className="container text-center">
          <p>
            &copy; 2024 {" "}  
              The Barber Den.
              Design by{" "}
            <a href="https://webwarp.com.br/" rel="nofollow" target="blank">
              WebWarp
            </a>
            .
          </p>
        </div>
      </div>
    </div>
  );
};

import styled from "styled-components";
import { Image } from "./image";
import React from "react";

const ImagesDiv = styled.div``;
const PortDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
`;

const CardImage = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px 0;
  @media (max-width: 768px) {
    margin: 10px
  }
`;

const CardTitle = styled.h3`
  color: #f0a500;
  margin-top: 5px;
`;

export const Gallery = (props) => {
  return (
    <div id="portfolio" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Galeria</h2>
          <p>
            Algumas fotos dos cortes realizados e finalizados.
          </p>
        </div>
        <ImagesDiv>
          <PortDiv>
            {props.data
              ? props.data.map((d, i) => (
                  <CardImage key={`${d.title}-${i}`}>
                    <Image
                      title={d.title}
                      largeImage={d.largeImage}
                      smallImage={d.smallImage}
                    />
                    <CardTitle>{d.title}</CardTitle>
                  </CardImage>
                ))
              : "Loading..."}
          </PortDiv>
        </ImagesDiv>
      </div>
    </div>
  );
};
